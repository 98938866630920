const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return (
      value.length === 0 ||
      value[0] === "" ||
      value[0] === null ||
      value[0] === undefined
    );
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return value === undefined || value === null || value === "";
};

export { isEmpty };
