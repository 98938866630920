import React, { useEffect, useState } from "react";
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";
import AdminNewPartner from "../layouts/AdminNewPartner";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import AdminSortingPartnerCitySelect from "../components/AdminSortingPartnerCitySelect";
import AdminSortingPartnerCategorySelect from "../components/AdminSortingPartnerCategorySelect";
import { getPartner, updatePartner } from "../actions/partner.action";
import PopupPartnerInfos from "../layouts/PopupPartnerInfos";
import PopupPartnerContent from "../layouts/PopupPartnerContent";
import AdminUpdatePartner from "../layouts/AdminUpdatePartner";
import AdminCategoryManagement from "../layouts/AdminCategoryManagement";

const AdminPartners = () => {
  const dataPartner = useSelector((state) => state.partnerReducer);
  const dataCity = useSelector((state) => state.cityReducer);
  const dataAuth = useSelector((state) => state.authReducer);
  const [isAdminPartner, setIsAdminPartner] = useState(false);
  const [namePartnerSorting, setNamePartnerSorting] = useState("");
  const [sortingCityValue, setSortingCityValue] = useState([]);
  const [sortingCityId, setSortingCityId] = useState("");
  const [sortingCategoryValue, setSortingCategoryValue] = useState([]);
  const [sortingCategoryName, setSortingCategoryName] = useState("");
  const [sortingActive, setSortingActive] = useState("all");
  const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [slicedFilteredPartners, setSlicedFilteredPartners] = useState([]);
  const [popupPartnerInfos, setPopupPartnerInfos] = useState({});
  const [popupPartnerContent, setPopupPartnerContent] = useState({});
  const [popupPartnerUpdate, setPopupPartnerUpdate] = useState({});
  const [currentPageTablePartner, setCurrentPageTablePartner] = useState(1);
  const itemsPerPage = 20;
  const indexOfLastItem = currentPageTablePartner * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const dispatch = useDispatch();

  const handleCitySelected = (value) => {
    setSortingCityValue(value);
  };

  const handleCategorySelected = (value) => {
    setSortingCategoryValue(value);
  };

  const normalizeString = (string) => {
    return string
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    if (!isEmpty(dataAuth)) {
      if (
        dataAuth.user.adminLevels.includes(1) ||
        dataAuth.user.adminLevels.includes(5)
      ) {
        setIsAdminPartner(true);
      }
    }
  }, [dataAuth]);

  useEffect(() => {
    if (!isEmpty(sortingCityValue)) {
      let tempValues = [];
      sortingCityValue.forEach((city) => {
        tempValues.push(city.value);
      });
      setSortingCityId(tempValues);
    } else {
      setSortingCityId("");
    }
  }, [sortingCityValue]);

  useEffect(() => {
    if (!isEmpty(sortingCategoryValue)) {
      let tempValues = [];
      sortingCategoryValue.forEach((category) => {
        tempValues.push(category.value);
      });
      setSortingCategoryName(tempValues);
    } else {
      setSortingCategoryName("");
    }
  }, [sortingCategoryValue]);

  useEffect(() => {
    const tempFilteredData = dataPartner
      .filter((partner) =>
        normalizeString(partner.name).includes(
          normalizeString(namePartnerSorting)
        )
      )
      .filter(
        (partner) =>
          isEmpty(sortingCityValue) || sortingCityId.includes(partner.city)
      )
      .filter(
        (partner) =>
          isEmpty(sortingCategoryValue) ||
          partner.category.some((category) => {
            return sortingCategoryName.includes(category);
          })
      )
      .filter((partner) => {
        if (sortingActive === "all") {
          return true;
        } else if (sortingActive === "active") {
          return partner.isActive;
        } else {
          return !partner.isActive;
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    if (isEmpty(tempFilteredData)) {
      setIsFilteredEmpty(true);
    } else {
      setIsFilteredEmpty(false);
    }
    setFilteredPartners(tempFilteredData);
    setCurrentPageTablePartner(1);
  }, [
    dataPartner,
    namePartnerSorting,
    sortingCityId,
    sortingCategoryName,
    sortingActive,
  ]);

  useEffect(() => {
    let tempSlicedPartner = filteredPartners.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    setSlicedFilteredPartners(tempSlicedPartner);
  }, [currentPageTablePartner, filteredPartners]);

  const handleRefreshPartners = () => {
    dispatch(getPartner());
    window.alert(
      "Tableau des partenaires actualisé.\nTous les partenaires sont à présent modifiables et activables."
    );
  };

  const handleActivatePartner = (e, partner) => {
    const updatedPartner = { ...partner, isActive: !partner.isActive };
    dispatch(updatePartner(updatedPartner));
  };

  const handleNextPageTablePartner = () => {
    setCurrentPageTablePartner(currentPageTablePartner + 1);
  };

  const handlePreviousPageTablePartner = () => {
    setCurrentPageTablePartner(currentPageTablePartner - 1);
  };

  return (
    <div className="partner-admin-container">
      <HeaderAdmin />
      <section className="admin-content-container">
        <NavbarAdmin />
        <div className="admin-content">
          {isAdminPartner && (
            <AdminNewPartner popupActive={popupPartnerUpdate} />
          )}
          <AdminCategoryManagement />
          <section className="partner-management">
            <h2>Gestion des partenaires</h2>
            <form>
              <div className="name-select-partner">
                <label htmlFor="partnerName">
                  <h4>Nom de partenaire :</h4>
                </label>
                <input
                  type="text"
                  name="partnerName"
                  id="partnerName"
                  value={namePartnerSorting}
                  onChange={(e) => setNamePartnerSorting(e.target.value)}
                  placeholder="Nom de partenaire"
                />
              </div>
              <div className="others-select-options">
                <h4>Choisir la ville :</h4>
                <AdminSortingPartnerCitySelect
                  onChange={handleCitySelected}
                  value={sortingCityValue}
                />
                <h4>Choisir les catégories :</h4>
                <AdminSortingPartnerCategorySelect
                  onChange={handleCategorySelected}
                  value={sortingCategoryValue}
                />
                <h4>Activité des partenaires</h4>
                <select
                  onChange={(e) => setSortingActive(e.target.value)}
                  value={sortingActive}
                  name="sortingPartner"
                  id="isActive"
                >
                  <option value="all">Actifs et inactifs</option>
                  <option value="active">Actifs seulement</option>
                  <option value="inactive">Inactifs seulement</option>
                </select>
              </div>
            </form>
            <button className="btn-refresh" onClick={handleRefreshPartners}>
              Actualiser <i className="bx bx-refresh"></i>
            </button>
            {isFilteredEmpty ? (
              <p>
                Aucun partenaire ne correspond à votre recherche. Veuillez
                réessayer.
              </p>
            ) : (
              <>
                <table className="partner-table">
                  <thead>
                    <tr>
                      <th>Nom du partenaire</th>
                      <th>Ville</th>
                      <th>Adresse</th>
                      <th>Catégories</th>
                      <th>Contacts</th>
                      <th>Contenu</th>
                      {isAdminPartner && (
                        <>
                          <th>Actif</th>
                          <th>Activation</th>
                        </>
                      )}
                      <th>Modifications</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(dataPartner) &&
                      slicedFilteredPartners.map((partner) => (
                        <tr key={partner._id}>
                          <td>{partner.name}</td>
                          <td>
                            {!isEmpty(dataCity) &&
                              dataCity.find((city) => city._id === partner.city)
                                .acronym +
                                " (" +
                                dataCity.find(
                                  (city) => city._id === partner.city
                                ).postalCode +
                                ")"}
                          </td>
                          <td>
                            {partner.streetNumber}{" "}
                            {partner.streetName ? partner.streetName : ""}
                          </td>
                          <td>{partner.category.join(", ")}</td>
                          <td>
                            <button
                              onClick={() => setPopupPartnerInfos(partner)}
                            >
                              Voir les contacts
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => setPopupPartnerContent(partner)}
                            >
                              Voir le contenu
                            </button>
                          </td>
                          {isAdminPartner && (
                            <>
                              <td>{partner.isActive ? "Oui" : "Non"}</td>
                              <td>
                                <button
                                  onClick={(e) =>
                                    handleActivatePartner(e, partner)
                                  }
                                >
                                  {partner.isActive ? "Désactiver" : "Activer"}
                                </button>
                              </td>
                            </>
                          )}
                          <td>
                            <button
                              onClick={() => setPopupPartnerUpdate(partner)}
                            >
                              Modifier
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>
                  <button
                    onClick={handlePreviousPageTablePartner}
                    hidden={currentPageTablePartner === 1}
                  >
                    Précédent
                  </button>
                  <button
                    onClick={handleNextPageTablePartner}
                    hidden={indexOfLastItem >= filteredPartners.length}
                  >
                    Suivant
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </section>
      {!isEmpty(popupPartnerInfos) && (
        <PopupPartnerInfos
          partner={popupPartnerInfos}
          closePopup={setPopupPartnerInfos}
        />
      )}
      {!isEmpty(popupPartnerContent) && (
        <PopupPartnerContent
          partner={popupPartnerContent}
          closePopup={setPopupPartnerContent}
        />
      )}
      {!isEmpty(popupPartnerUpdate) && (
        <AdminUpdatePartner
          partner={popupPartnerUpdate}
          closePopup={setPopupPartnerUpdate}
        />
      )}
    </div>
  );
};

export default AdminPartners;
