import {
  GET_PARTNER,
  SET_PARTNER,
  UPDATE_PARTNER,
} from "../actions/partner.action";

const initialState = {};

export default function partnerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNER:
      return action.payload;
    case SET_PARTNER:
      return [action.payload, ...state];
    case UPDATE_PARTNER:
      return state.map((partner) =>
        partner._id === action.payload._id ? action.payload : partner
      );
    default:
      return state;
  }
}
