import React, { useEffect, useState } from "react";
import logoLBP from "../assets/images/logo-les-bons-plans.png";
import { isEmpty } from "../assets/Utils";
import { useSelector } from "react-redux";
import CityListEntry from "../components/CityListEntry";
import BackButton from "../components/BackButton";
import LogoLbp from "../components/LogoLbp";

const CitiesList = () => {
  const dataCity = useSelector((state) => state.cityReducer);
  const [currentLocation, setCurrentLocation] = useState({});
  const [sortedCities, setSortedCities] = useState([]);

  const collectDataLocation = (pos) => {
    const crd = pos.coords;
    const data = {
      latitude: crd.latitude,
      longitude: crd.longitude,
    };
    setCurrentLocation(data);
  };

  const getCoordinates = async (city) => {
    const apiKey = "3e4b994188404404a254eb09e60b8d1b";
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      city
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry;
        return [lat, lng];
      }
      console.log("Coordinates not found for the city:", city);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(collectDataLocation);
  }, [dataCity]);

  const distanceCalculation = async (city) => {
    const coords = await getCoordinates(city.name);
    const deltaLat = coords[0] - currentLocation.latitude;
    const deltaLng = coords[1] - currentLocation.longitude;
    return Math.sqrt(deltaLat * deltaLat + deltaLng * deltaLng);
  };
  const sortCities = async (dataCity) => {
    const distances = await Promise.all(
      dataCity.map((city) => distanceCalculation(city))
    );

    return dataCity.sort(
      (a, b) => distances[dataCity.indexOf(a)] - distances[dataCity.indexOf(b)]
    );
  };

  useEffect(() => {
    if (!isEmpty(dataCity) && !isEmpty(currentLocation)) {
      sortCities(dataCity).then(setSortedCities);
    }
  }, [dataCity, currentLocation]);

  const handleFormChange = (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    const filteredCities = dataCity.filter((city) =>
      city.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSortedCities(filteredCities);
  };

  return (
    <div className="cities-list-container">
      <section className="header-container">
        <BackButton navPath="ma-ville" stringBtn="Retour à ma ville" />
        <LogoLbp />
      </section>
      <section className="city-selector">
        <div className="city-searchbar-container">
          <h1>Choisissez votre ville</h1>
          <form>
            <input
              type="text"
              placeholder="Entrez le nom d'une ville que vous souhaitez chercher..."
              onChange={(e) => handleFormChange(e)}
            />
          </form>
        </div>
        <div className="city-display">
          {!isEmpty(sortedCities) &&
            sortedCities
              .filter((city) => city.isActive === true)
              .map((city) => <CityListEntry key={city.id} city={city} />)}
        </div>
      </section>
    </div>
  );
};

export default CitiesList;
