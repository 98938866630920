import axios from "axios";

export const GET_CITY = "GET_CITY";
export const SET_CITY = "SET_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const CURRENT_CITY = "CURRENT_CITY";

export const getCity = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/city")
      .then((res) => {
        dispatch({ type: GET_CITY, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setCity = (city) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/city", city)
      .then((res) => {
        dispatch({ type: SET_CITY, payload: city });
      })
      .catch((err) => console.log(err));
  };
};

export const updateCity = (city) => {
  return async (dispatch) => {
    return axios
      .put("https://api.lesbonsplans.pro" + "/city/" + city._id, city)
      .then((res) => {
        dispatch({ type: UPDATE_CITY, payload: { ...res.data, ...city } });
      })
      .catch((err) => console.log(err));
  };
};

export const setCurrentCity = (city) => {
  return async (dispatch) => {
    dispatch({ type: CURRENT_CITY, payload: city });
  };
};
