import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import {
  getCategories,
  setCategory,
  updateCategory,
} from "../actions/category.action";

const AdminCategoryManagement = () => {
  const dataCategoryManagement = useSelector((state) => state.categoryReducer);
  const [newCategory, setNewCategory] = useState("");
  const [modifiedCategory, setModifiedCategory] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const copyDataCategoryManagement = [...dataCategoryManagement];
  const currentItems = copyDataCategoryManagement
    .sort((a, b) => a.name.localeCompare(b.name))
    .slice(indexOfFirstItem, indexOfLastItem);
  const dispatch = useDispatch();

  const handleCreateCategory = () => {
    const newCategoryObject = {
      name: newCategory,
      isActive: true,
    };
    dispatch(setCategory(newCategoryObject));
    setNewCategory("");
  };

  const handleRefreshCategories = () => {
    dispatch(getCategories());
    window.alert(
      "Tableau des catégories actualisé.\nToute les catégories sont à présent modifiables et activables."
    );
  };

  const handleActivateCategory = (e, category) => {
    const updatedCategory = { ...category, isActive: !category.isActive };
    dispatch(updateCategory(updatedCategory));
  };

  const handleUpdateCategory = () => {
    dispatch(updateCategory(modifiedCategory));
    setModifiedCategory({});
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <section className="category-management">
      <h2>Gestion des catégories</h2>
      <div className="new-category">
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button
          onClick={() => handleCreateCategory()}
          disabled={newCategory.length < 1}
          id={newCategory.length < 1 ? "disabledCreateCat" : ""}
        >
          Créer la catégorie
        </button>
      </div>
      <button className="btn-refresh" onClick={() => handleRefreshCategories()}>
        Actualiser <i className="bx bx-refresh"></i>
      </button>
      <table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Actif</th>
            <th className="contain-btn">Activation</th>
            <th className="contain-btn">Modifier</th>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(currentItems) &&
            currentItems.map((category) => (
              <tr key={category._id}>
                <td>
                  {category._id ? (
                    modifiedCategory._id === category._id ? (
                      <input
                        type="text"
                        defaultValue={category.name}
                        onChange={(e) =>
                          setModifiedCategory({
                            ...modifiedCategory,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      category.name
                    )
                  ) : (
                    category.name
                  )}
                </td>
                <td>{category.isActive ? "Oui" : "Non"}</td>
                <td className="contain-btn">
                  <button onClick={(e) => handleActivateCategory(e, category)}>
                    {category.isActive ? "Désactiver" : "Activer"}
                  </button>
                </td>
                <td className="contain-btn">
                  {category._id ? (
                    modifiedCategory._id === category._id ? (
                      <>
                        <button onClick={() => setModifiedCategory({})}>
                          Annuler
                        </button>
                        <button onClick={() => handleUpdateCategory()}>
                          Valider les modifications
                        </button>
                      </>
                    ) : (
                      <button onClick={() => setModifiedCategory(category)}>
                        Modifier
                      </button>
                    )
                  ) : (
                    <button disabled>Actualisez pour modifier</button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div>
        <button onClick={handlePreviousPage} hidden={currentPage === 1}>
          Précédent
        </button>
        <button
          onClick={handleNextPage}
          hidden={indexOfLastItem >= dataCategoryManagement.length}
        >
          Suivant
        </button>
      </div>
    </section>
  );
};

export default AdminCategoryManagement;
