import React, { useEffect, useState } from "react";
import axios from "axios";
import { isEmpty } from "../assets/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAudioGuidedTour,
  setAudioGuidedTour,
} from "../actions/contentGuidedTour.action";
import {
  deletePictureGuidedTour,
  resetPictureGuidedTour,
  setPictureGuidedTour,
} from "../actions/pictureGuidedTour.action";
import { setGuidedTour } from "../actions/guidedTour.action";

const AdminNewGuidedTour = ({ resetPopup }) => {
  const [dataCountries, setDataCountries] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const pictureNewGuidedTour = useSelector(
    (state) => state.pictureGuidedTourReducer
  );
  const [currentCountryUploaded, setCurrentCountryUploaded] = useState("");
  const audioUploaded = useSelector((state) => state.contentGuidedTourReducer);
  const [allAudioUploaded, setAllAudioUploaded] = useState({});
  const dispatch = useDispatch();

  const handleRemoveCountry = (country) => {
    setSelectedCountry(selectedCountry.filter((c) => c.cca3 !== country.cca3));
  };

  useEffect(() => {
    if (!isEmpty(audioUploaded)) {
      setAllAudioUploaded({
        ...allAudioUploaded,
        [currentCountryUploaded]: audioUploaded,
      });
    }
  }, [audioUploaded]);

  const handlePictureUploaded = async (e) => {
    const picture = e.target.files[0];
    let formData = new FormData();
    formData.append("file", picture);
    dispatch(await deletePictureGuidedTour(pictureNewGuidedTour));
    dispatch(await setPictureGuidedTour(formData));
  };

  const handleAudioUploaded = (e, countryCca3) => {
    const audio = e.target.files[0];
    const formData = new FormData();
    formData.append("file", audio);
    setCurrentCountryUploaded(countryCca3);
    dispatch(setAudioGuidedTour(formData));
  };

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => setDataCountries(res.data));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    let newGuidedTour = {
      name: form["name-guided-tour"].value,
      picture: pictureNewGuidedTour,
      contentAudio: allAudioUploaded,
    };

    newGuidedTour.coordinates = [
      parseFloat(form["latitude-guided-tour"].value.replace(",", ".")),
      parseFloat(form["longitude-guided-tour"].value.replace(",", ".")),
    ];

    dispatch(setGuidedTour(newGuidedTour));
    dispatch(resetAudioGuidedTour());
    dispatch(resetPictureGuidedTour());
    resetPopup();
  };

  return (
    <div className="popup popup-new-guided-tour">
      <div className="popup-content-new-guided-tour-container">
        <div className="popup-guided-tour-header">
          <h2>Créer une visite guidée</h2>
          <div className="cancel-button" onClick={resetPopup}>
            <p>Annuler</p>
            <i className="bx bx-x-circle"></i>
          </div>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <section className="guided-tour-infos">
            <div className="label-info-guided-tour-container">
              <label htmlFor="name-guided-tour">Nom de la visite</label>
              <input
                type="text"
                id="name-guided-tour"
                name="name-guided-tour"
                required
              />
              <label htmlFor="coordinates-guided-tour">Latitude du lieu</label>
              <input
                type="text"
                id="latitude-guided-tour"
                name="latitude-guided-tour"
                required
              />
              <label htmlFor="coordinates-guided-tour">Longitude du lieu</label>
              <input
                type="text"
                id="longitude-guided-tour"
                name="longitude-guided-tour"
                required
              />
              <label className="label-btn" htmlFor="picture-guided-tour">
                Importer une image d'illustration
              </label>
              <input
                type="file"
                id="picture-guided-tour"
                name="picture-guided-tour"
                accept="image/*"
                onChange={handlePictureUploaded}
                required
                hidden
              />
            </div>
            {!isEmpty(pictureNewGuidedTour) && (
              <img
                src={
                  "https://api.lesbonsplans.pro" +
                  "/upload/upload/image/" +
                  pictureNewGuidedTour
                }
                alt="Image de la visite guidée"
              />
            )}
          </section>
          <section className="guided-tour-audio">
            <div className="searchbar-country">
              <label htmlFor="language-audio">Chercher un pays :</label>
              <input
                type="text"
                id="language-audio"
                value={searchCountry}
                onChange={(e) => setSearchCountry(e.target.value)}
              />
            </div>
            <div className="flags-container">
              {dataCountries &&
                dataCountries
                  .filter((country) =>
                    country.translations.fra.common
                      .toLowerCase()
                      .includes(searchCountry.toLowerCase())
                  )
                  .filter(
                    (country) =>
                      !selectedCountry.some((c) => c.cca3 === country.cca3)
                  )
                  .sort((a, b) => b.population - a.population)
                  .slice(0, 30)
                  .map((country) => (
                    <div
                      key={country.cca3}
                      onClick={() => {
                        setSelectedCountry([...selectedCountry, country]);
                        setSearchCountry("");
                      }}
                    >
                      <label htmlFor={country.cca3}>
                        {country.translations.fra.common}
                      </label>
                      <img
                        src={country.flags.png}
                        alt={"Drapeau " + country.translations.fra.common}
                      />
                    </div>
                  ))}
            </div>
            <h3>Ajoutez un audio pour les pays</h3>
            <div className="audio-each-language">
              {!isEmpty(selectedCountry) &&
                selectedCountry.map((country) => (
                  <div key={country.cca3}>
                    <i
                      onClick={() => handleRemoveCountry(country)}
                      className="bx bx-x-circle"
                    ></i>
                    <div className="center-audio-country">
                      <label htmlFor={country.cca3}>
                        {country.translations.fra.common}
                        <img
                          src={country.flags.png}
                          alt={"Drapeau " + country.translations.fra.common}
                        />
                      </label>
                      {allAudioUploaded[country.cca3] && (
                        <audio controls>
                          <source
                            src={
                              "https://api.lesbonsplans.pro" +
                              "/upload/upload/audio/" +
                              allAudioUploaded[country.cca3]
                            }
                            type="audio/mp3"
                          />
                        </audio>
                      )}
                    </div>
                    <input
                      type="file"
                      id={country.cca3}
                      name={country.cca3}
                      accept="audio/*"
                      onChange={(e) => handleAudioUploaded(e, country.cca3)}
                      hidden
                      required
                    />
                  </div>
                ))}
            </div>
          </section>
          <input type="submit" value="Créer la visite guidée" />
        </form>
      </div>
    </div>
  );
};

export default AdminNewGuidedTour;
