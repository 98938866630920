import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { NavLink } from "react-router-dom";

const GuidedTourList = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const currentCityContent = useSelector((state) => state.contentCityReducer);
  const dataGuidedTour = useSelector((state) => state.guidedTourReducer);
  const [guidedTourList, setGuidedTourList] = useState([]);

  useEffect(() => {
    if (!isEmpty(currentCityContent) && !isEmpty(dataGuidedTour)) {
      const guidedTourListTemp = dataGuidedTour.filter((tour) =>
        currentCityContent.guidedTour.includes(tour._id)
      );
      setGuidedTourList(guidedTourListTemp);
    }
  }, []);

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };
  return (
    <div className="guided-tour-list-container" style={cityBackground}>
      <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"} />
      <section className="guided-tour-list">
        {!isEmpty(guidedTourList) &&
          guidedTourList.map((tour) => (
            <div className="guided-tour-list-entry" key={tour._id}>
              <NavLink to={"/visite-guidee"} state={tour}>
                <img
                  src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/upload/image/" +
                    tour.picture
                  }
                  alt={"Image de la visite guidée " + tour.name}
                />
                <h2>{tour.name}</h2>
              </NavLink>
            </div>
          ))}
      </section>
    </div>
  );
};

export default GuidedTourList;
