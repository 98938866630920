import React from "react";
import Header from "../layouts/Header";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const MagazineRedirect = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };
  return (
    <div className="magazine-redirect-container" style={cityBackground}>
      <Header navPath={"decouverte"} stringBtn={"Retourner découvrir"} />
      <h1>Les magazines</h1>
      <section className="magazine-redirect">
        <ul>
          <li>
            <NavLink to={"/nos-magazines"}>
              <i className="bx bx-book-open"></i>Nos magazines
            </NavLink>
          </li>
          {contentCurrentCity.cityMagLink && (
            <li>
              <NavLink to={"/magazine-de-la-ville"}>
                <i className="bx bx-podcast"></i>Magazine de la ville
              </NavLink>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};

export default MagazineRedirect;
