import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import PartnerListEntry from "../components/PartnerListEntry";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";

const PartnersList = () => {
  const dataPartner = useSelector((state) => state.partnerReducer);
  const currentCity = useSelector((state) => state.cityReducer);
  const currentCategory = useSelector((state) => state.categoryReducer);
  const [filteredPartner, setFilteredPartner] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [sortedPartner, setSortedPartner] = useState([]);

  const collectDataLocation = (pos) => {
    const crd = pos.coords;
    const data = {
      latitude: crd.latitude,
      longitude: crd.longitude,
    };
    setCurrentLocation(data);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(collectDataLocation);
  }, []);

  const distanceCalculation = (partner) => {
    const coords = partner.coordinates;
    if (!isEmpty(coords)) {
      const deltaLat = 100 * (coords[0] - currentLocation.latitude);
      const deltaLng = 100 * (coords[1] - currentLocation.longitude);
      return Math.sqrt(Math.pow(deltaLat, 2) + Math.pow(deltaLng, 2));
    }
    return 1000;
  };

  const sortPartners = (partners) => {
    const distances = partners.map((city) => distanceCalculation(city));

    console.log(distances);

    return partners.sort(
      (a, b) => distances[partners.indexOf(a)] - distances[partners.indexOf(b)]
    );
  };

  useEffect(() => {
    if (!isEmpty(filteredPartner) && !isEmpty(currentLocation)) {
      setSortedPartner(sortPartners(filteredPartner));
    }
  }, [filteredPartner, currentLocation]);

  useEffect(() => {
    const tempFilteredPart = dataPartner
      .filter((part) => part.city === currentCity._id)
      .filter((part) => part.category.includes(currentCategory.name))
      .filter((part) => part.isActive);
    setFilteredPartner(tempFilteredPart);
  }, [dataPartner]);

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };

  return (
    <div className="partners-list-container" style={cityBackground}>
      <Header navPath="categories" stringBtn="Retourner aux catégories" />
      <section className="partners-list">
        {!isEmpty(filteredPartner) &&
          sortedPartner.map((part) => <PartnerListEntry partner={part} />)}
      </section>
    </div>
  );
};

export default PartnersList;
