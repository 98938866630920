import {
  GET_CONTENTCITY,
  GET_CONTENTCITY_BY_ID,
  SET_CONTENTCITY,
  UPDATE_CONTENTCITY,
} from "../actions/contentCity.action";

const initialState = [];

export default function contentCityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTENTCITY:
      return action.payload;
    case GET_CONTENTCITY_BY_ID:
      return action.payload;
    case SET_CONTENTCITY:
      return action.payload;
    case UPDATE_CONTENTCITY:
      return [action.payload];
    default:
      return state;
  }
}
