import React, { useState } from "react";
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import AdminNewCity from "../layouts/AdminNewCity";
import PopupCitySocialMedia from "../layouts/PopupCitySocialMedia";
import AdminUpdateCity from "../layouts/AdminUpdateCity";
import { updateCity } from "../actions/city.action";

const AdminCities = () => {
  const dataCity = useSelector((state) => state.cityReducer);
  const [popupCitySocialMedia, setPopupCitySocialMedia] = useState({});
  const [popupCityUpdate, setPopupCityUpdate] = useState({});
  const dispatch = useDispatch();

  const handleActivateCity = (e, city) => {
    const updatedCity = { ...city, isActive: !city.isActive };
    dispatch(updateCity(updatedCity));
  };

  return (
    <div className="city-admin-container">
      <HeaderAdmin />
      <section className="admin-content-container">
        <NavbarAdmin />
        <div className="admin-content">
          <AdminNewCity />
          <section className="city-management">
            <h2>Gestion des villes</h2>
            <table className="city-table">
              <thead>
                <tr>
                  <th>Pays</th>
                  <th>Nom</th>
                  <th>Code Postal</th>
                  <th>Acronyme</th>
                  <th>Image d'arrière plan</th>
                  <th>Logo</th>
                  <th>Réseaux sociaux</th>
                  <th>Actif</th>
                  <th>Activation</th>
                  <th>Modifications</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(dataCity) &&
                  dataCity.map((city) => (
                    <tr key={city.acronym}>
                      <td>{city.country}</td>
                      <td>{city.name}</td>
                      <td>{city.postalCode}</td>
                      <td>{city.acronym}</td>
                      <td>
                        <img
                          className="city-background-image"
                          src={`${"https://api.lesbonsplans.pro"}/upload/city/image/${
                            city.picture
                          }`}
                          alt={"Image d'arrière plan " + city.name}
                        />
                      </td>
                      <td>
                        <img
                          className="city-logo"
                          src={`${"https://api.lesbonsplans.pro"}/upload/city/image/${
                            city.logo
                          }`}
                          alt={"Logo " + city.name}
                        />
                      </td>
                      <td>
                        <button onClick={() => setPopupCitySocialMedia(city)}>
                          Réseaux sociaux
                        </button>
                      </td>
                      <td>{city.isActive ? "Oui" : "Non"}</td>
                      <td onClick={(e) => handleActivateCity(e, city)}>
                        <button>
                          {city.isActive ? "Désactiver" : "Activer"}
                        </button>
                      </td>
                      <td>
                        <button onClick={() => setPopupCityUpdate(city)}>
                          Modifier
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </section>
        </div>
      </section>
      {!isEmpty(popupCitySocialMedia) && (
        <PopupCitySocialMedia
          city={popupCitySocialMedia}
          closePopup={setPopupCitySocialMedia}
        />
      )}
      {!isEmpty(popupCityUpdate) && (
        <AdminUpdateCity
          city={popupCityUpdate}
          closePopup={setPopupCityUpdate}
        />
      )}
    </div>
  );
};

export default AdminCities;
