import axios from "axios";

export const GET_CONTENTCITY = "GET_CONTENTCITY";
export const GET_CONTENTCITY_BY_ID = "GET_CONTENTCITY_BY_ID";
export const SET_CONTENTCITY = "SET_CONTENTCITY";
export const UPDATE_CONTENTCITY = "UPDATE_CONTENTCITY";

export const getContentCity = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/contentCity")
      .then((res) => {
        dispatch({ type: GET_CONTENTCITY, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getContentCityById = (contentCityId) => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/contentCity/" + contentCityId)
      .then((res) => {
        dispatch({ type: GET_CONTENTCITY_BY_ID, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setContentCity = (contentCity) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/contentCity", contentCity)
      .then((res) => {
        dispatch({ type: SET_CONTENTCITY, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const updateContentCity = (contentCity) => {
  return async (dispatch) => {
    return axios
      .put(
        "https://api.lesbonsplans.pro" + "/contentCity/" + contentCity._id,
        contentCity
      )
      .then((res) => {
        dispatch({
          type: UPDATE_CONTENTCITY,
          payload: { ...res.data, ...contentCity },
        });
      })
      .catch((err) => console.log(err));
  };
};
