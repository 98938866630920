import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";

const News = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const [linkSrcMag, setLinkSrcMag] = useState("");

  useEffect(() => {
    if (currentCity.acronym === "LGM") {
      setLinkSrcMag(
        "https://www.la-grande-motte.info/la-grande-motte-le-magazine-gratuit"
      );
    } else if (currentCity.acronym === "LGDR") {
      setLinkSrcMag("https://www.viben.fr/guide-gdr-les-magazines");
    }
  }, []);

  return (
    <div className="news-container">
      <Header navPath={"magazines"} stringBtn={"Retourner aux magazines"} />
      <section className="iframe-container">
        <iframe title="news" src={linkSrcMag} frameborder="0"></iframe>
      </section>
    </div>
  );
};

export default News;
