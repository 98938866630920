import axios from "axios";

export const GET_PARTNER = "GET_PARTNER";
export const SET_PARTNER = "SET_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";

export const getPartner = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/partner")
      .then((res) => {
        dispatch({ type: GET_PARTNER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setPartner = (partner) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/partner", partner)
      .then((res) => {
        dispatch({ type: SET_PARTNER, payload: partner });
      })
      .catch((err) => console.log(err));
  };
};

export const updatePartner = (partner) => {
  return async (dispatch) => {
    return axios
      .put("https://api.lesbonsplans.pro" + "/partner/" + partner._id, partner)
      .then((res) => {
        dispatch({
          type: UPDATE_PARTNER,
          payload: { ...res.data, ...partner },
        });
      })
      .catch((err) => console.log(err));
  };
};
