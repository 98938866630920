import axios from "axios";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const CURRENT_CATEGORY = "CURRENT_CATEGORY";

export const getCategories = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/category")
      .then((res) => {
        dispatch({ type: GET_CATEGORIES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setCategory = (category) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/category", category)
      .then((res) => {
        dispatch({ type: SET_CATEGORY, payload: category });
      })
      .catch((err) => console.log(err));
  };
};

export const updateCategory = (category) => {
  return async (dispatch) => {
    return axios
      .put(
        "https://api.lesbonsplans.pro" + "/category/" + category._id,
        category
      )
      .then((res) => {
        dispatch({
          type: UPDATE_CATEGORY,
          payload: { ...res.data, ...category },
        });
      });
  };
};

export const setCurrentCategory = (category) => {
  return async (dispatch) => {
    dispatch({ type: CURRENT_CATEGORY, payload: category });
  };
};
