import { combineReducers } from "redux";
import cityReducer from "./city.reducer";
import partnerReducer from "./partner.reducer";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import cityUploadsReducer from "./cityUploads.reducer";
import categoryReducer from "./category.reducer";
import partnerUploadsReducer from "./partnerUploads.reducer";
import contentCityReducer from "./contentCity.reducer";
import guidedTourReducer from "./guidedTour.reducer";
import contentGuidedTourReducer from "./contentGuidedTour.reducer";
import pictureGuidedTourReducer from "./pictureGuidedTour.reducer";

export default combineReducers({
  categoryReducer,
  cityReducer,
  cityUploadsReducer,
  contentCityReducer,
  partnerReducer,
  partnerUploadsReducer,
  guidedTourReducer,
  contentGuidedTourReducer,
  pictureGuidedTourReducer,
  authReducer,
  userReducer,
});
