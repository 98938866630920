import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const PartnerListEntry = ({ partner }) => {
  const currentCity = useSelector((state) => state.cityReducer);
  return (
    <div className="partner-entry">
      <NavLink to="/partenaire" state={partner}>
        <img
          src={
            "https://api.lesbonsplans.pro" +
            "/upload/partner/image/" +
            partner.logo
          }
          alt={"Vignette partenaire " + partner.name}
        />
        <div className="text-container">
          <h2>{partner.name}</h2>
          <p>
            {`${partner.streetNumber}, ${
              partner.streetName ? partner.streetName : ""
            }`}
            <span>
              <br />
              {`${currentCity.postalCode} ${currentCity.name}`}
            </span>
          </p>
        </div>
      </NavLink>
    </div>
  );
};

export default PartnerListEntry;
