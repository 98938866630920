import React from "react";
import { useSelector } from "react-redux";
import Header from "../layouts/Header";

const LiveCam = () => {
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  return (
    <div className="live-cam-container">
      <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"} />
      <section className="iframe-container">
        <iframe
          title="webcam"
          src={contentCurrentCity.liveCamLink}
          frameborder="0"
        ></iframe>
      </section>
    </div>
  );
};

export default LiveCam;
