import React from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";

const MagCity = () => {
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  return (
    <div className="mag-city-container">
      <Header stringBtn={"Retourner aux magazines"} navPath={"magazines"} />
      <section className="iframe-container">
        <iframe
          title="magazine-ville"
          src={contentCurrentCity.cityMagLink}
          frameborder="0"
        ></iframe>
      </section>
    </div>
  );
};

export default MagCity;
