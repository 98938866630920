import React from "react";
import { useLocation } from "react-router";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import logoWeb from "../assets/images/logo-web.png";

const Partner = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  let location = useLocation();
  const partner = location.state;

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };
  return (
    <div className="partner-profile-container" style={cityBackground}>
      <Header navPath="nos-partenaires" stringBtn="Retour aux partenaires" />
      <section className="partner-infos">
        <img
          src={
            "https://api.lesbonsplans.pro" +
            "/upload/partner/image/" +
            partner.logo
          }
          alt={"Vignette partenaire " + partner.name}
        />
        <div className="text-content-partner-container">
          <h1>{partner.name}</h1>
          {!isEmpty(partner.coordinates) ? (
            <a
              href={`https://www.google.com/maps?q=${partner.coordinates[0]},${partner.coordinates[1]}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${partner.streetNumber}, ${
                partner.streetName ? partner.streetName : ""
              }`}{" "}
              <br />
              {` ${currentCity.postalCode} ${currentCity.name}`}
            </a>
          ) : (
            <a
              href={`https://www.google.com/maps?q=${partner.streetNumber} ${
                partner.streetName ? partner.streetName : ""
              } ${currentCity.postalCode} ${currentCity.name}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${partner.streetNumber}, ${
                partner.streetName ? partner.streetName : ""
              }`}
              <br />
              {` ${currentCity.postalCode} ${currentCity.name}`}
            </a>
          )}
          {partner.contentText && <p>{partner.contentText}</p>}
        </div>
        <div className="content-uploads-partner-container">
          {!isEmpty(partner.contentUploads) && (
            <div className="content-partner-uploads">
              {partner.contentUploads.map((upload) => (
                <div key={upload.id} className="content-partner-upload">
                  {upload.type === "picture" && (
                    <img
                      src={
                        "https://api.lesbonsplans.pro" +
                        "/upload/partner/image/" +
                        upload.id
                      }
                      alt={"Image " + partner.name}
                    />
                  )}
                  {upload.type === "video" && (
                    <video controls>
                      <source
                        src={
                          "https://api.lesbonsplans.pro" +
                          "/upload/partner/video/" +
                          upload.id
                        }
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {(partner.website ||
          partner.phoneNumber ||
          partner.emailAdress ||
          !isEmpty(partner.socialMedia)) && (
          <div className="contact-partner-container">
            <h2>Contacts</h2>
            <div className="info-contact-partner-container">
              {partner.website && (
                <a href={partner.website} target="_blank" rel="noreferrer">
                  <img src={logoWeb} alt="logo-web" />
                </a>
              )}
              {partner.phoneNumber && (
                <a href={`tel:${partner.phoneNumber}`}>
                  <i className="bx bxs-phone"></i>
                </a>
              )}
              {partner.emailAddress && (
                <a href={`mailto:${partner.emailAddress}`}>
                  <i className="bx bxs-envelope"></i>
                </a>
              )}
              {partner.socialMedia.google && (
                <a
                  href={partner.socialMedia.google}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-google"></i>
                </a>
              )}
              {partner.socialMedia.facebook && (
                <a
                  href={partner.socialMedia.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-facebook-circle"></i>
                </a>
              )}
              {partner.socialMedia.instagram && (
                <a
                  href={partner.socialMedia.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-instagram-alt"></i>
                </a>
              )}
              {partner.socialMedia.twitter && (
                <a
                  href={partner.socialMedia.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-twitter"></i>
                </a>
              )}
              {partner.socialMedia.pinterest && (
                <a
                  href={partner.socialMedia.pinterest}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-pinterest"></i>
                </a>
              )}
              {partner.socialMedia.tiktok && (
                <a
                  href={partner.socialMedia.tiktok}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-tiktok"></i>
                </a>
              )}
              {partner.socialMedia.youtube && (
                <a
                  href={partner.socialMedia.youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-youtube"></i>
                </a>
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Partner;
