import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setCurrentCity } from "../actions/city.action";
import { getContentCityById } from "../actions/contentCity.action";

const CityListEntry = ({ city }) => {
  const dispatch = useDispatch();
  const onClickRedirect = (currentCity) => {
    dispatch(setCurrentCity(currentCity));
    dispatch(getContentCityById(currentCity.contentCity));
  };
  return (
    <div className="city-list-entry-container">
      <NavLink
        to="/accueil"
        key={city._id}
        state={city}
        onClick={() => onClickRedirect(city)}
      >
        <img
          src={
            "https://api.lesbonsplans.pro" +
            "/upload/city/image/" +
            city.picture
          }
          alt={"Photo ville " + city.name}
        />
        <button>{city.name}</button>
      </NavLink>
    </div>
  );
};

export default CityListEntry;
