import {
  CURRENT_CATEGORY,
  GET_CATEGORIES,
  SET_CATEGORY,
  UPDATE_CATEGORY,
} from "../actions/category.action";

const initialState = {};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return action.payload;
    case SET_CATEGORY:
      return [...state, action.payload];
    case UPDATE_CATEGORY:
      return state.map((category) =>
        category._id === action.payload._id ? action.payload : category
      );
    case CURRENT_CATEGORY:
      return action.payload;
    default:
      return state;
  }
}
