import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const NavbarAdmin = () => {
  const adminLevels = useSelector(
    (state) => state.authReducer.user.adminLevels
  );

  return (
    <nav className="nav-admin-container">
      <ul>
        {adminLevels.includes(1) && (
          <li title="Création / modification d'utilisateurs administrateurs">
            <NavLink
              to="/administration/utilisateurs"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Utilisateurs
            </NavLink>
          </li>
        )}
        {adminLevels.some((level) => [1, 2].includes(level)) && (
          <li title="Création / modification de villes">
            <NavLink
              to="/administration/gestion-villes"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Gérer villes
            </NavLink>
          </li>
        )}
        {adminLevels.some((level) => [1, 3, 4].includes(level)) && (
          <li title="Création / modification du contenu dans les villes">
            <NavLink
              to="/administration/contenu-ville"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Contenu ville
            </NavLink>
          </li>
        )}
        {adminLevels.some((level) => [1, 5, 6].includes(level)) && (
          <li title="Création / modification de partenaires">
            <NavLink
              to="/administration/partenaires"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Partenaires
            </NavLink>
          </li>
        )}
        {adminLevels.some((level) => [1, 20].includes(level)) && (
          <li title="Envoi de notifications push aux utilisateurs PWA">
            <NavLink
              to="/administration/notifications"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Notifications
            </NavLink>
          </li>
        )}
        {adminLevels.some((level) => [1, 30].includes(level)) && (
          <li title="Statistiques du site web">
            <NavLink
              to="/administration/statistiques"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Statistiques
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavbarAdmin;
