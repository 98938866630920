import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { NavLink } from "react-router-dom";
import CityListEntry from "../components/CityListEntry";
import LogoLbp from "../components/LogoLbp";
import InstallPWA from "../components/InstallPwa";

const HomeRedirect = () => {
  const [nearestCity, setNearestCity] = useState({});
  const dataCity = useSelector((state) => state.cityReducer);

  const collectDataLocation = (pos) => {
    const crd = pos.coords;
    const data = {
      latitude: crd.latitude,
      longitude: crd.longitude,
    };
    findNearestCity(data);
  };

  const findNearestCity = async (currentLocation) => {
    let nearestCityPos = [0, 0];
    if (isEmpty(dataCity) || !Array.isArray(dataCity)) return;
    for (const city of dataCity) {
      let coords = [];
      if (city.isActive === true) {
        coords = await getCoordinates(city.name);
      } else {
        coords = [0, 0];
      }
      let deltaLatNear = nearestCityPos[0] - currentLocation.latitude;
      let deltaLngNear = nearestCityPos[1] - currentLocation.longitude;
      let deltaLat = coords[0] - currentLocation.latitude;
      let deltaLng = coords[1] - currentLocation.longitude;
      if (
        deltaLat * deltaLat + deltaLng * deltaLng <
        deltaLatNear * deltaLatNear + deltaLngNear * deltaLngNear
      ) {
        nearestCityPos = coords;
        setNearestCity(city);
      }
    }
  };

  const getCoordinates = async (city) => {
    const apiKey = "3e4b994188404404a254eb09e60b8d1b";
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      city
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry;
        return [lat, lng];
      }
      console.log("Coordinates not found for the city:", city);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(collectDataLocation);
  }, [dataCity]);

  return (
    <div className="home-redirect-container">
      <InstallPWA />
      <section className="logo-container">
        <LogoLbp />
      </section>
      <section className="city-selector">
        <h1>Choisissez votre ville</h1>
        <div className="city-display">
          {Array.isArray(dataCity) &&
            !isEmpty(dataCity) &&
            dataCity
              .filter((city) => city.isActive === true)
              .filter((city) => city.name === nearestCity.name)
              .map((city) => (
                <CityListEntry key={city._id ? city._id : 1} city={city} />
              ))}
          <div className="other-cities">
            <div className="button-cities-container">
              <NavLink to="/selection-ville">
                <button>
                  <i className="bx bxs-buildings"></i>
                  <span>Choisir une autre ville</span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeRedirect;
