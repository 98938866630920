import React from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";

const PracticalInfos = () => {
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  return (
    <div className="practical-info-container">
      <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"} />
      <section className="iframe-container">
        <iframe
          title="infos-pratiques"
          src={contentCurrentCity.infoCityLink}
          frameborder="0"
        ></iframe>
      </section>
    </div>
  );
};

export default PracticalInfos;
