import React from "react";
import { isEmpty } from "../assets/Utils";

const PopupCitySocialMedia = ({ city, closePopup }) => {
  return (
    <div className="popup popup-city-social-media">
      <div className="popup-content-city-container">
        <div className="popup-content-city-header">
          <img
            src={
              "https://api.lesbonsplans.pro" + "/upload/city/image/" + city.logo
            }
            alt={"Logo " + city.name}
          />
          <i onClick={() => closePopup({})} className="bx bx-x-circle"></i>
        </div>
        <div className="popup-content-city-social-media">
          {!isEmpty(city.socialMedia) ? (
            <div className="city-social-media">
              <h3>Réseaux sociaux :</h3>
              {city.socialMedia.google && (
                <a
                  href={city.socialMedia.google}
                  target="_blank"
                  rel="noreferrer"
                  title="Google"
                >
                  <i className="bx bxl-google"></i>
                </a>
              )}
              {city.socialMedia.facebookp && (
                <a
                  href={city.socialMedia.facebookp}
                  target="_blank"
                  rel="noreferrer"
                  title="Page Facebook"
                >
                  <i className="bx bxl-facebook-circle"></i>
                </a>
              )}
              {city.socialMedia.facebookg && (
                <a
                  href={city.socialMedia.facebookg}
                  target="_blank"
                  rel="noreferrer"
                  title="Groupe Facebook"
                >
                  <i className="bx bxl-facebook-circle"></i>
                </a>
              )}
              {city.socialMedia.instagram && (
                <a
                  href={city.socialMedia.instagram}
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <i className="bx bxl-instagram-alt"></i>
                </a>
              )}
              {city.socialMedia.twitter && (
                <a
                  href={city.socialMedia.twitter}
                  target="_blank"
                  rel="noreferrer"
                  title="Twitter"
                >
                  <i className="bx bxl-twitter"></i>
                </a>
              )}
              {city.socialMedia.pinterest && (
                <a
                  href={city.socialMedia.pinterest}
                  target="_blank"
                  rel="noreferrer"
                  title="Pinterest"
                >
                  <i className="bx bxl-pinterest"></i>
                </a>
              )}
              {city.socialMedia.tiktok && (
                <a
                  href={city.socialMedia.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  title="Tiktok"
                >
                  <i className="bx bxl-tiktok"></i>
                </a>
              )}
              {city.socialMedia.youtube && (
                <a
                  href={city.socialMedia.youtube}
                  target="_blank"
                  rel="noreferrer"
                  title="YouTube"
                >
                  <i className="bx bxl-youtube"></i>
                </a>
              )}
            </div>
          ) : (
            <p>Aucun réseau social</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupCitySocialMedia;
