import React, { useEffect, useState } from "react";
import { isEmpty } from "../assets/Utils";
import {
  deleteElementCity,
  resetReducerCityUploads,
  setBackgroundCity,
  setLogoCity,
} from "../actions/cityUploads.action";
import { useDispatch, useSelector } from "react-redux";
import { updateCity } from "../actions/city.action";

const AdminUpdateCity = ({ city, closePopup }) => {
  const uploadsCity = useSelector((state) => state.cityUploadsReducer);
  const [isFormValid, setIsFormValid] = useState(true);
  const [pictureUpdateCity, setPictureUpdateCity] = useState(city.picture);
  const [logoUpdateCity, setLogoUpdateCity] = useState(city.logo);
  const [socialMediaUpdateCity, setSocialMediaUpdateCity] = useState(
    city.socialMedia
  );
  const [updatingCity, setUpdatingCity] = useState(city);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(uploadsCity.logo)) {
      setLogoUpdateCity(uploadsCity.logo.fileId);
    }
  }, [uploadsCity.logo]);

  useEffect(() => {
    if (!isEmpty(uploadsCity.background)) {
      setPictureUpdateCity(uploadsCity.background.fileId);
    }
  }, [uploadsCity.background]);

  const handleIsFormValid = () => {
    if (
      !isEmpty(updatingCity.country) &&
      !isEmpty(updatingCity.name) &&
      !isEmpty(updatingCity.acronym) &&
      !isEmpty(updatingCity.postalCode)
    ) {
      setIsFormValid(true);
      return;
    }
    setIsFormValid(false);
  };

  const handlePictureChanged = async (e) => {
    const file = e.target.files[0];
    let formDataPicture = new FormData();
    formDataPicture.append("file", file);
    dispatch(await deleteElementCity(pictureUpdateCity));
    dispatch(await setBackgroundCity(formDataPicture));
    handleIsFormValid();
  };

  const handleLogoChanged = async (e) => {
    const file = e.target.files[0];
    let formDataLogo = new FormData();
    formDataLogo.append("file", file);
    dispatch(await deleteElementCity(logoUpdateCity));
    dispatch(await setLogoCity(formDataLogo));
    handleIsFormValid();
  };

  const handleSocialMediaChanged = (e) => {
    const input = e.target;
    if (isEmpty(input.value)) {
      const tempSocialMedia = { ...socialMediaUpdateCity };
      delete tempSocialMedia[input.name];
      setSocialMediaUpdateCity(tempSocialMedia);
      return;
    }
    setSocialMediaUpdateCity({
      ...socialMediaUpdateCity,
      [input.name]: input.value,
    });
    setUpdatingCity({ ...updatingCity, socialMedia: socialMediaUpdateCity });
    handleIsFormValid();
  };

  const resetPopup = () => {
    dispatch(resetReducerCityUploads());
    closePopup({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatesCity = {};
    let isChanged = false;
    if (updatingCity.country !== city.country) {
      updatesCity.country = updatingCity.country;
      isChanged = true;
    }
    if (updatingCity.name !== city.name) {
      updatesCity.name = updatingCity.name;
      isChanged = true;
    }
    if (updatingCity.acronym !== city.acronym) {
      updatesCity.acronym = updatingCity.acronym;
      isChanged = true;
    }
    if (updatingCity.postalCode !== city.postalCode) {
      updatesCity.postalCode = updatingCity.postalCode;
      isChanged = true;
    }
    if (socialMediaUpdateCity !== city.socialMedia) {
      updatesCity.socialMedia = socialMediaUpdateCity;
      isChanged = true;
    }
    if (pictureUpdateCity !== city.picture) {
      updatesCity.picture = pictureUpdateCity;
      isChanged = true;
    }
    if (logoUpdateCity !== city.logo) {
      updatesCity.logo = logoUpdateCity;
      isChanged = true;
    }
    if (isChanged) {
      updatesCity._id = city._id;
      dispatch(updateCity(updatesCity));
    }
    resetPopup();
  };

  return (
    <div className="popup popup-city-update">
      <div className="popup-content-city-update-container">
        <div className="popup-city-update-header">
          <h2>Mise à jour d'une ville</h2>
          <div className="cancel-button" onClick={resetPopup}>
            <p>Annuler</p>
            <i className="bx bx-x-circle"></i>
          </div>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <section className="update-city-setup">
            <div className="info-update-city">
              <h3>Informations</h3>
              <div className="info-update-city-container">
                <label htmlFor="country">Pays :</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  defaultValue={city.country}
                  onChange={(e) => {
                    setUpdatingCity({
                      ...updatingCity,
                      country: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  required
                />
                <label htmlFor="name">Nom :</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  defaultValue={city.name}
                  onChange={(e) => {
                    setUpdatingCity({ ...updatingCity, name: e.target.value });
                    handleIsFormValid();
                  }}
                  required
                />
                <label htmlFor="acronym">Acronyme :</label>
                <input
                  type="text"
                  id="acronym"
                  name="acronym"
                  defaultValue={city.acronym}
                  onChange={(e) => {
                    setUpdatingCity({
                      ...updatingCity,
                      acronym: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  required
                />
                <label htmlFor="postalCode">Code Postal :</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  defaultValue={city.postalCode}
                  onChange={(e) => {
                    setUpdatingCity({
                      ...updatingCity,
                      postalCode: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  required
                />
              </div>
              <div className="social-media-update-city">
                <h3>Réseaux sociaux</h3>
                <div className="social-media-update-city-container">
                  <div className="google-update social-media-update">
                    <label htmlFor="google">Google :</label>
                    <input
                      type="text"
                      id="google"
                      name="google"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.google : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.google.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="facebookp-update social-media-update">
                    <label htmlFor="facebookp">Page Facebook :</label>
                    <input
                      type="text"
                      id="facebookp"
                      name="facebookp"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.facebookp : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.facebook.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="facebookg-update social-media-update">
                    <label htmlFor="facebookg">Groupe Facebook :</label>
                    <input
                      type="text"
                      id="facebookg"
                      name="facebookg"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.facebookg : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.facebook.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="instagram-update social-media-update">
                    <label htmlFor="instagram">Instagram :</label>
                    <input
                      type="text"
                      id="instagram"
                      name="instagram"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.instagram : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.instagram.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="twitter-update social-media-update">
                    <label htmlFor="twitter">Twitter :</label>
                    <input
                      type="text"
                      id="twitter"
                      name="twitter"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.twitter : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.twitter.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="pinterest-update social-media-update">
                    <label htmlFor="pinterest">Pinterest :</label>
                    <input
                      type="text"
                      id="pinterest"
                      name="pinterest"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.pinterest : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.pinterest.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="tiktok-update social-media-update">
                    <label htmlFor="tiktok">TikTok :</label>
                    <input
                      type="text"
                      id="tiktok"
                      name="tiktok"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.tiktok : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.tiktok.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="youtube-update social-media-update">
                    <label htmlFor="youtube">Youtube :</label>
                    <input
                      type="text"
                      id="youtube"
                      name="youtube"
                      defaultValue={
                        city.socialMedia ? city.socialMedia.youtube : ""
                      }
                      pattern="https?://.+"
                      title="Exemple: https://www.youtube.com/..."
                      onChange={handleSocialMediaChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="uploads-update-city">
            <div className="uploads-update-city-container">
              <div className="button-uploads-container">
                <div className="upload-background-image">
                  <label htmlFor="background-image-city">
                    Modifier l'image d'arrière plan
                  </label>
                  <input
                    type="file"
                    id="background-image-city"
                    name="background-image-city"
                    onChange={handlePictureChanged}
                    accept="image/*"
                    hidden
                  />
                </div>
                <div className="upload-logo">
                  <label htmlFor="logo-city">Modifier le logo</label>
                  <input
                    type="file"
                    id="logo-city"
                    name="logo-city"
                    onChange={handleLogoChanged}
                    accept="image/*"
                    hidden
                  />
                </div>
              </div>
              <div className="render-uploads-update-city">
                <img
                  className="city-background-image"
                  src={`${"https://api.lesbonsplans.pro"}/upload/city/image/${pictureUpdateCity}`}
                  alt={"Image d'arrière plan " + city.name}
                />
                <img
                  className="city-logo"
                  src={`${"https://api.lesbonsplans.pro"}/upload/city/image/${logoUpdateCity}`}
                  alt={"Logo " + city.name}
                />
              </div>
            </div>
          </section>
          <input
            id={isFormValid ? "valid" : "invalid"}
            type="submit"
            value="Mettre à jour la ville"
          />
        </form>
      </div>
    </div>
  );
};

export default AdminUpdateCity;
