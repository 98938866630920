import {
  GET_GUIDED_TOUR,
  SET_GUIDED_TOUR,
  UPDATE_GUIDED_TOUR,
} from "../actions/guidedTour.action";

const initialState = [];

export default function guidedTourReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GUIDED_TOUR:
      return action.payload;
    case SET_GUIDED_TOUR:
      return action.payload;
    case UPDATE_GUIDED_TOUR:
      return [action.payload];
    default:
      return state;
  }
}
