import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const createOption = (label) => ({
  label,
  value: label,
});

const CitySelector = ({ onChange, value }) => {
  const dataCity = useSelector((state) => state.cityReducer);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let tempOptions = [];
    dataCity.forEach((city) => {
      tempOptions.push(createOption(city.name));
    });
    setOptions(tempOptions);
  }, [dataCity]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: "20px 0",
      padding: "5px",
      minWidth: "50%",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#b1852d",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
    />
  );
};

export default CitySelector;
