import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { setPartner } from "../actions/partner.action";
import Select from "react-select";
import CategorySelector from "../components/CategorySelector";
import {
  deleteElement,
  resetReducer,
  setLogoPartner,
  setPicturePartner,
  setVideoPartner,
} from "../actions/partnerUploads.action";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";

const AdminNewPartner = ({ popupActive }) => {
  const dataCity = useSelector((state) => state.cityReducer);
  const dataPartner = useSelector((state) => state.partnerReducer);
  const contentNewPartner = useSelector((state) => state.partnerUploadsReducer);
  const [partnerName, setPartnerName] = useState("");
  const [partnerSubDomain, setPartnerSubDomain] = useState("");
  const [coordinatesOn, setCoordinatesOn] = useState(false);
  const [cityNewPartner, setCityNewPartner] = useState({});
  const [categoryNewPartner, setCategoryNewPartner] = useState([]);
  const [socialMediaNewPartner, setSocialMediaNewPartner] = useState({
    ok: "ok",
  });
  const [optionsCity, setOptionsCity] = useState([{}]);
  const [logoNewPartner, setLogoNewPartner] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const dispatch = useDispatch();

  const normalizeString = (string) => {
    return string
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    if (!isEmpty(partnerName)) {
      const partSubDomContent = normalizeString(partnerName.toLowerCase())
        .replaceAll(" ", "-")
        .replaceAll("'", "");
      let subDomainAvailable = 0;
      dataPartner.forEach((partner) => {
        if (partner.subDomain === partSubDomContent) {
          subDomainAvailable += 1;
        }
      });
      if (subDomainAvailable > 0) {
        if (isEmpty(cityNewPartner)) {
          setPartnerSubDomain(
            partSubDomContent + subDomainAvailable.toString()
          );
        } else {
          setPartnerSubDomain(
            partSubDomContent +
              subDomainAvailable.toString() +
              "." +
              dataCity.find((city) => cityNewPartner.value === city._id)
                .subDomain
          );
        }
      } else {
        if (isEmpty(cityNewPartner)) {
          setPartnerSubDomain(partSubDomContent);
        } else {
          setPartnerSubDomain(
            partSubDomContent +
              "." +
              dataCity.find((city) => cityNewPartner.value === city._id)
                .subDomain
          );
        }
      }
    } else {
      setPartnerSubDomain("");
    }
  }, [partnerName, cityNewPartner]);

  useEffect(() => {
    let tempOptionsCity = [];
    dataCity.forEach((city) => {
      let tempLabel = `${city.name} (${city.postalCode})`;
      tempOptionsCity.push({ value: city._id, label: tempLabel });
    });
    setOptionsCity(tempOptionsCity);
  }, []);

  useEffect(() => {
    if (!isEmpty(contentNewPartner.logo)) {
      setLogoNewPartner(contentNewPartner.logo);
    }
  }, [contentNewPartner.logo]);

  const handleIsFormValid = () => {
    const form = document.getElementById("form-new-partner");
    if (form.checkValidity() && !isEmpty(cityNewPartner)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleCitySelected = (value) => {
    setCityNewPartner(value);
    handleIsFormValid();
  };

  const handleCoordinateChecked = (e) => {
    if (e.target.checked) {
      setCoordinatesOn(true);
    } else {
      setCoordinatesOn(false);
    }
    handleIsFormValid();
  };

  const onChangeCategorySelector = (values) => {
    setCategoryNewPartner(values);
    handleIsFormValid();
  };

  const handleSocialMediaChanged = (e) => {
    const input = e.target;
    if (isEmpty(input.value)) {
      const tempSocialMedia = { ...socialMediaNewPartner };
      delete tempSocialMedia[input.name];
      setSocialMediaNewPartner(tempSocialMedia);
      return;
    }
    setSocialMediaNewPartner({
      ...socialMediaNewPartner,
      [input.name]: input.value,
    });
    handleIsFormValid();
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    let formDataLogo = new FormData();
    formDataLogo.append("file", file);
    if (!isEmpty(logoNewPartner)) {
      dispatch(await deleteElement(logoNewPartner));
      dispatch(await setLogoPartner(formDataLogo));
      return;
    }
    dispatch(await setLogoPartner(formDataLogo));
    handleIsFormValid();
  };

  const handlePictureUpload = async (e) => {
    const file = e.target.files[0];
    let formDataPicture = new FormData();
    formDataPicture.append("file", file);
    dispatch(await setPicturePartner(formDataPicture));
    handleIsFormValid();
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    let formDataVideo = new FormData();
    formDataVideo.append("file", file);
    dispatch(await setVideoPartner(formDataVideo));
    handleIsFormValid();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }
    const form = e.target;

    let tempCategories = [];
    categoryNewPartner.forEach((category) => {
      tempCategories.push(category.value);
    });
    let uploadsNewPartner = contentNewPartner.uploads;
    let newPartner = {
      name: form.name.value,
      city: cityNewPartner.value,
      streetNumber: form["street-number"].value,
      streetName: form["street-name"].value,
      category: tempCategories,
      logo: contentNewPartner.logo,
      phoneNumber: form.tel.value,
      emailAddress: form.email.value,
      website: form.website.value,
      contentText: form.description.value,
      contentUploads: uploadsNewPartner,
      socialMedia: socialMediaNewPartner,
      subDomain: partnerSubDomain,
      isActive: false,
    };
    if (coordinatesOn) {
      newPartner.coordinates = [
        parseFloat(form.latitude.value.replace(",", ".")),
        parseFloat(form.longitude.value.replace(",", ".")),
      ];
    }
    dispatch(setPartner(newPartner));
    form["is-coordinates"].checked = false;
    setCategoryNewPartner([]);
    setCoordinatesOn(false);
    setCityNewPartner("");
    setSocialMediaNewPartner({});
    setLogoNewPartner("");
    setPartnerName("");
    setPartnerSubDomain("");
    dispatch(resetReducer());
    form.reset();
  };

  const customStylesCitySelector = {
    control: (provided) => ({
      ...provided,
      margin: "20px 0",
      padding: "5px",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      padding: "2px 8px",
      borderRadius: "4px",
    }),
  };

  return (
    <section className="new-partner">
      <h2>Création d'un nouveau partenaire</h2>
      <form id="form-new-partner" autoComplete="off" onSubmit={handleSubmit}>
        <section className="new-partner-setup">
          <div className="info-new-partner">
            <h3>Informations</h3>
            <label htmlFor="name">Nom : </label>
            <input
              type="text"
              id="name"
              onChange={(e) => {
                setPartnerName(e.target.value);
                handleIsFormValid();
              }}
              value={partnerName}
              placeholder="Nom du partenaire"
              required
            />
            <label htmlFor="tel">Téléphone : </label>
            <input
              type="tel"
              id="tel"
              onChange={handleIsFormValid}
              placeholder="Numéro de téléphone"
            />
            <label htmlFor="email">Email : </label>
            <input
              type="email"
              id="email"
              onChange={handleIsFormValid}
              placeholder="Adresse e-mail"
            />
            <label htmlFor="website">Site web : </label>
            <input
              type="url"
              id="website"
              onChange={handleIsFormValid}
              placeholder="Site web du partenaire"
              pattern="https?://.+"
            />
            <label htmlFor="subDomain">Sous-domaine</label>
            <input
              type="text"
              id="subDomain"
              onChange={(e) => {
                setPartnerSubDomain(e.target.value);
                handleIsFormValid();
              }}
              value={partnerSubDomain}
              placeholder="Sous-domaine du partenaire"
              pattern="[a-z0-9-]+"
            />
            <p>.lesbonsplans.pro</p>
          </div>
          <div className="city-new-partner">
            <h3>Ville attitrée</h3>
            <section className="city-selector">
              <Select
                options={optionsCity}
                onChange={handleCitySelected}
                styles={customStylesCitySelector}
                value={!isEmpty(cityNewPartner) ? cityNewPartner : null}
                placeholder="Sélectionnez une ville"
              />
            </section>
            {!isEmpty(cityNewPartner) &&
              dataCity
                .filter((city) => city._id === cityNewPartner.value)
                .map((city) => (
                  <section key={city._id} className="location-info">
                    <div className="without-coordinates">
                      <h3>Adresse pour {city.name}</h3>
                      <label htmlFor="street-number">Adresse 1 : </label>
                      <input
                        type="text"
                        id="street-number"
                        onChange={handleIsFormValid}
                        required
                      />
                      <label htmlFor="street-name">Adresse 2 : </label>
                      <input
                        type="text"
                        id="street-name"
                        onChange={handleIsFormValid}
                      />
                      <label
                        id="btn-coordinates-activaction"
                        htmlFor="is-coordinates"
                      >
                        {coordinatesOn
                          ? "Désactiver les coordonnées GPS"
                          : "Activer les coordonnées GPS"}
                      </label>
                      <input
                        type="checkbox"
                        id="is-coordinates"
                        onChange={handleCoordinateChecked}
                        hidden
                      />
                    </div>
                    <div className="with-coordinates">
                      {coordinatesOn && (
                        <div className="coordinates-city">
                          <label htmlFor="latitude">Latitude :</label>
                          <input type="text" id="latitude" required />
                          <label htmlFor="longitude">Longitude :</label>
                          <input type="text" id="longitude" required />
                        </div>
                      )}
                    </div>
                  </section>
                ))}
          </div>
          <div className="categories-new-partner">
            <h3>Catégories</h3>
            <CategorySelector
              onChange={onChangeCategorySelector}
              value={categoryNewPartner}
            />
            <div className="description-new-partner">
              <label htmlFor="description" id="label-description">
                Description du partenaire :
              </label>
              <textarea id="description" />
            </div>
          </div>
          <div className="social-media-new-partner">
            <h3>Réseaux sociaux</h3>
            <label htmlFor="google">Google :</label>
            <input
              type="text"
              id="google"
              name="google"
              pattern="https?://.+"
              title="Exemple: https://www.google.com/..."
              onChange={handleSocialMediaChanged}
            />
            <label htmlFor="facebook">Page Facebook :</label>
            <input
              type="text"
              id="facebook"
              name="facebook"
              pattern="https?://.+"
              title="Exemple: https://www.facebook.com/..."
              onChange={handleSocialMediaChanged}
            />
            <label htmlFor="instagram">Instagram :</label>
            <input
              type="text"
              id="instagram"
              name="instagram"
              pattern="https?://.+"
              title="Exemple: https://www.instagram.com/..."
              onChange={handleSocialMediaChanged}
            />
            <label htmlFor="twitter">Twitter :</label>
            <input
              type="text"
              id="twitter"
              name="twitter"
              pattern="https?://.+"
              title="Exemple: https://www.twitter.com/..."
              onChange={handleSocialMediaChanged}
            />
            <label htmlFor="pinterest">Pinterest :</label>
            <input
              type="text"
              id="pinterest"
              name="pinterest"
              pattern="https?://.+"
              title="Exemple: https://www.pinterest.com/..."
              onChange={handleSocialMediaChanged}
            />
            <label htmlFor="tiktok">TikTok :</label>
            <input
              type="text"
              id="tiktok"
              name="tiktok"
              pattern="https?://.+"
              title="Exemple: https://www.tiktok.com/..."
              onChange={handleSocialMediaChanged}
            />
            <label htmlFor="youtube">Youtube :</label>
            <input
              type="text"
              id="youtube"
              name="youtube"
              pattern="https?://.+"
              title="Exemple: https://www.youtube.com/..."
              onChange={handleSocialMediaChanged}
            />
          </div>
        </section>
        <section className="new-partner-content">
          <h3>Contenu</h3>
          <div className="new-partner-content-container">
            <label htmlFor="logo-content">
              {isEmpty(contentNewPartner.logo)
                ? "Ajouter un logo"
                : "Modifier le logo"}
            </label>
            <input
              type="file"
              id="logo-content"
              onChange={handleLogoUpload}
              hidden
              required
            />
            <label htmlFor="picture-content">Ajouter une photo</label>
            <input
              type="file"
              id="picture-content"
              onChange={handlePictureUpload}
              hidden
            />
            <label htmlFor="video-content">Ajouter une vidéo</label>
            <input
              type="file"
              id="video-content"
              onChange={handleVideoUpload}
              hidden
            />
          </div>
        </section>

        {!isEmpty(contentNewPartner.logo) && isEmpty(popupActive) && (
          <section className="uploaded-logo">
            <h3>Logo ajouté</h3>
            <img
              src={
                "https://api.lesbonsplans.pro" +
                "/upload/partner/image/" +
                contentNewPartner.logo
              }
              alt="Logo ajouté"
              width={200}
            />
          </section>
        )}
        {!isEmpty(contentNewPartner.uploads) && isEmpty(popupActive) && (
          <AdminSortingPartnerUploads />
        )}
        <input
          id={isFormValid ? "valid" : "invalid"}
          type="submit"
          value="Créer le partenaire"
        />
      </form>
    </section>
  );
};

export default AdminNewPartner;
