import axios from "axios";

export const GET_GUIDED_TOUR = "GET_GUIDED_TOUR";
export const SET_GUIDED_TOUR = "SET_GUIDED_TOUR";
export const UPDATE_GUIDED_TOUR = "UPDATE_GUIDED_TOUR";

export const getGuidedTour = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/guidedTour")
      .then((res) => {
        dispatch({ type: GET_GUIDED_TOUR, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setGuidedTour = (guidedTour) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/guidedTour", guidedTour)
      .then((res) => {
        dispatch({ type: SET_GUIDED_TOUR, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const updateGuidedTour = (guidedTour) => {
  return async (dispatch) => {
    return axios
      .put(
        "https://api.lesbonsplans.pro" + "/guidedTour/" + guidedTour.id,
        guidedTour
      )
      .then((res) => {
        dispatch({
          type: UPDATE_GUIDED_TOUR,
          payload: { ...res.data, ...guidedTour },
        });
      })
      .catch((err) => console.log(err));
  };
};
