import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteElement,
  reorderElements,
  resetReducer,
  setLogoPartner,
  setPicturePartner,
  setVideoPartner,
} from "../actions/partnerUploads.action";
import { isEmpty } from "../assets/Utils";
import { updatePartner } from "../actions/partner.action";
import CategorySelector from "../components/CategorySelector";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";

const AdminUpdatePartner = ({ partner, closePopup }) => {
  const contentUpdatePartner = useSelector(
    (state) => state.partnerUploadsReducer
  );
  const [categoryUpdatePartner, setCategoryUpdatePartner] = useState([]);
  const [socialMediaUpdatePartner, setSocialMediaUpdatePartner] = useState(
    partner.socialMedia
  );
  const [logoUpdatePartner, setLogoUpdatePartner] = useState(partner.logo);
  const [uploadsUpdatePartner, setUploadsUpdatePartner] = useState(
    partner.contentUploads
  );
  const [updatingPartner, setUpdatingPartner] = useState(partner);
  const [isFormValid, setIsFormValid] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let tempDefaultCategories = [];
    partner.category.forEach((category) => {
      tempDefaultCategories.push({
        label: category,
        value: category,
      });
    });
    setCategoryUpdatePartner(tempDefaultCategories);
    dispatch(reorderElements(uploadsUpdatePartner));
  }, []);

  useEffect(() => {
    if (!isEmpty(contentUpdatePartner.logo)) {
      setLogoUpdatePartner(contentUpdatePartner.logo);
    }
  }, [contentUpdatePartner.logo]);

  const handleIsFormValid = () => {
    if (!isEmpty(updatingPartner.name)) {
      setIsFormValid(true);
      return;
    }
    setIsFormValid(false);
  };

  const onChangeCategorySelector = (values) => {
    setCategoryUpdatePartner(values);
    let tempCategories = [];
    values.forEach((category) => {
      tempCategories.push(category.value);
    });
    setUpdatingPartner({
      ...updatingPartner,
      category: tempCategories,
    });
    handleIsFormValid();
  };

  const handleSocialMediaChanged = (e) => {
    const input = e.target;
    if (isEmpty(input.value)) {
      const tempSocialMedia = { ...socialMediaUpdatePartner };
      delete tempSocialMedia[input.name];
      setSocialMediaUpdatePartner(tempSocialMedia);
      return;
    }
    setSocialMediaUpdatePartner({
      ...socialMediaUpdatePartner,
      [input.name]: input.value,
    });
    setUpdatingPartner({
      ...updatePartner,
      socialMedia: socialMediaUpdatePartner,
    });
    handleIsFormValid();
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    let formDataLogo = new FormData();
    formDataLogo.append("file", file);
    dispatch(await deleteElement(logoUpdatePartner));
    dispatch(await setLogoPartner(formDataLogo));
    handleIsFormValid();
  };

  const handlePictureUpload = async (e) => {
    const file = e.target.files[0];
    let formDataPicture = new FormData();
    formDataPicture.append("file", file);
    dispatch(await setPicturePartner(formDataPicture));
    handleIsFormValid();
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    let formDataVideo = new FormData();
    formDataVideo.append("file", file);
    dispatch(await setVideoPartner(formDataVideo));
    handleIsFormValid();
  };

  const resetUpdatePopup = () => {
    dispatch(resetReducer());
    closePopup({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    let updatesPartner = {};
    let isChanged = false;

    if (updatingPartner.name !== partner.name) {
      updatesPartner = { ...updatesPartner, name: updatingPartner.name };
      isChanged = true;
    }
    if (updatingPartner.phoneNumber !== partner.phoneNumber) {
      updatesPartner = {
        ...updatesPartner,
        phoneNumber: updatingPartner.phoneNumber,
      };
      isChanged = true;
    }
    if (updatingPartner.emailAddress !== partner.emailAddress) {
      updatesPartner = {
        ...updatesPartner,
        emailAddress: updatingPartner.emailAddress,
      };
      isChanged = true;
    }
    if (updatingPartner.website !== partner.website) {
      updatesPartner = { ...updatesPartner, website: updatingPartner.website };
      isChanged = true;
    }
    if (updatingPartner.streetNumber !== partner.streetNumber) {
      updatesPartner = {
        ...updatesPartner,
        streetNumber: updatingPartner.streetNumber,
      };
      isChanged = true;
    }
    if (updatingPartner.streetName !== partner.streetName) {
      updatesPartner = {
        ...updatesPartner,
        streetName: updatingPartner.streetName,
      };
      isChanged = true;
    }
    if (updatingPartner.contentText !== partner.contentText) {
      updatesPartner = {
        ...updatesPartner,
        contentText: updatingPartner.contentText,
      };
      isChanged = true;
    }

    let tempCategories = [];
    categoryUpdatePartner.forEach((category) => {
      tempCategories.push(category.value);
    });
    if (tempCategories !== partner.category) {
      updatesPartner = { ...updatesPartner, category: tempCategories };
      isChanged = true;
    }
    if (socialMediaUpdatePartner !== partner.socialMedia) {
      updatesPartner = {
        ...updatesPartner,
        socialMedia: socialMediaUpdatePartner,
      };
      isChanged = true;
    }
    if (logoUpdatePartner !== partner.logo) {
      updatesPartner = { ...updatesPartner, logo: logoUpdatePartner };
      isChanged = true;
    }
    if (contentUpdatePartner.uploads !== partner.contentUploads) {
      updatesPartner = {
        ...updatesPartner,
        contentUploads: contentUpdatePartner.uploads,
      };
      isChanged = true;
    }
    if (isChanged) {
      updatesPartner = { ...updatesPartner, _id: partner._id };
      dispatch(updatePartner(updatesPartner));
    }
    resetUpdatePopup();
  };

  return (
    <section className="popup update-partner">
      <div className="popup-update-container">
        <div className="popup-update-partner-header">
          <h2>Mise à jour d'un partenaire</h2>
          <div className="cancel-button" onClick={resetUpdatePopup}>
            <p>Annuler</p>
            <i className="bx bx-x-circle"></i>
          </div>
        </div>
        <form
          id="form-update-partner"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <section className="update-partner-setup">
            <div className="info-update-partner">
              <h3>Informations</h3>
              <div className="info-update-partner-container">
                <label htmlFor="name">Nom : </label>
                <input
                  type="text"
                  id="name"
                  defaultValue={partner.name}
                  onChange={(e) => {
                    setUpdatingPartner({
                      ...updatingPartner,
                      name: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  placeholder="Nom du partenaire"
                  required
                />
                <label htmlFor="tel">Téléphone : </label>
                <input
                  type="tel"
                  id="tel"
                  name="tel"
                  defaultValue={partner.phoneNumber}
                  onChange={(e) => {
                    setUpdatingPartner({
                      ...updatingPartner,
                      phoneNumber: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  placeholder="Numéro de téléphone"
                />
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  defaultValue={partner.emailAddress}
                  onChange={(e) => {
                    setUpdatingPartner({
                      ...updatingPartner,
                      emailAddress: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  placeholder="Adresse e-mail"
                />
                <label htmlFor="website">Site web : </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  defaultValue={partner.website}
                  onChange={(e) => {
                    setUpdatingPartner({
                      ...updatingPartner,
                      website: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  placeholder="Site web du partenaire"
                  pattern="https?://.+"
                />
              </div>
              <div className="adress-update">
                <label htmlFor="street-number">Adresse 1 : </label>
                <input
                  type="text"
                  id="street-number"
                  name="street-number"
                  defaultValue={partner.streetNumber}
                  onChange={(e) => {
                    setUpdatingPartner({
                      ...updatingPartner,
                      streetNumber: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  placeholder="Adresse 1"
                />
                <label htmlFor="street-name">Adresse 2 : </label>
                <input
                  type="text"
                  id="street-name"
                  name="street-name"
                  defaultValue={partner.streetName}
                  onChange={(e) => {
                    setUpdatingPartner({
                      ...updatingPartner,
                      streetName: e.target.value,
                    });
                    handleIsFormValid();
                  }}
                  placeholder="Adresse 2"
                />
              </div>
            </div>
            <div className="description-update-partner">
              <label htmlFor="description" id="label-description">
                Description du partenaire :
              </label>
              <textarea
                id="description"
                defaultValue={partner.contentText}
                onChange={(e) => {
                  setUpdatingPartner({
                    ...updatingPartner,
                    contentText: e.target.value,
                  });
                  handleIsFormValid();
                }}
              />
            </div>
            <div className="categories-update-partner">
              <h3>Catégories</h3>
              <CategorySelector
                onChange={onChangeCategorySelector}
                value={categoryUpdatePartner}
              />
            </div>
            <div className="social-media-update-partner">
              <h3>Réseaux sociaux</h3>
              <div className="social-media-update-partner-container">
                <div className="google-update social-media-update">
                  <label htmlFor="google">Google :</label>
                  <input
                    type="text"
                    id="google"
                    name="google"
                    defaultValue={partner.socialMedia.google}
                    pattern="https?://.+"
                    title="Exemple: https://www.google.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
                <div className="facebook-update social-media-update">
                  <label htmlFor="facebook">Page Facebook :</label>
                  <input
                    type="text"
                    id="facebook"
                    name="facebook"
                    defaultValue={partner.socialMedia.facebook}
                    pattern="https?://.+"
                    title="Exemple: https://www.facebook.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
                <div className="instagram-update social-media-update">
                  <label htmlFor="instagram">Instagram :</label>
                  <input
                    type="text"
                    id="instagram"
                    name="instagram"
                    defaultValue={partner.socialMedia.instagram}
                    pattern="https?://.+"
                    title="Exemple: https://www.instagram.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
                <div className="twitter-update social-media-update">
                  <label htmlFor="twitter">Twitter :</label>
                  <input
                    type="text"
                    id="twitter"
                    name="twitter"
                    defaultValue={partner.socialMedia.twitter}
                    pattern="https?://.+"
                    title="Exemple: https://www.twitter.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
                <div className="pinterest-update social-media-update">
                  <label htmlFor="pinterest">Pinterest :</label>
                  <input
                    type="text"
                    id="pinterest"
                    name="pinterest"
                    defaultValue={partner.socialMedia.pinterest}
                    pattern="https?://.+"
                    title="Exemple: https://www.pinterest.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
                <div className="tiktok-update social-media-update">
                  <label htmlFor="tiktok">TikTok :</label>
                  <input
                    type="text"
                    id="tiktok"
                    name="tiktok"
                    defaultValue={partner.socialMedia.tiktok}
                    pattern="https?://.+"
                    title="Exemple: https://www.tiktok.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
                <div className="youtube-update social-media-update">
                  <label htmlFor="youtube">Youtube :</label>
                  <input
                    type="text"
                    id="youtube"
                    name="youtube"
                    defaultValue={partner.socialMedia.youtube}
                    pattern="https?://.+"
                    title="Exemple: https://www.youtube.com/..."
                    onChange={handleSocialMediaChanged}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="update-partner-content">
            <h3>Contenu</h3>
            <div className="update-partner-content-container">
              <label htmlFor="logo-content">Modifier le logo</label>
              <input
                type="file"
                id="logo-content"
                name="file-logo"
                onChange={handleLogoUpload}
                hidden
              />
              <label htmlFor="picture-content">Ajouter une photo</label>
              <input
                type="file"
                id="picture-content"
                name="file-picture"
                onChange={handlePictureUpload}
                hidden
              />
              <label htmlFor="video-content">Ajouter une vidéo</label>
              <input
                type="file"
                id="video-content"
                name="file-video"
                onChange={handleVideoUpload}
                hidden
              />
            </div>
          </section>
          {!isEmpty(logoUpdatePartner) && (
            <section className="uploaded-logo">
              <h3>Logo actuel</h3>
              <img
                src={
                  "https://api.lesbonsplans.pro" +
                  "/upload/partner/image/" +
                  logoUpdatePartner
                }
                alt="Logo ajouté"
                title="Logo actuel du partenaire"
                width={200}
              />
            </section>
          )}
          {!isEmpty(uploadsUpdatePartner) && <AdminSortingPartnerUploads />}
          <input
            id={isFormValid ? "valid" : "invalid"}
            type="submit"
            value="Mettre à jour le partenaire"
          />
        </form>
      </div>
    </section>
  );
};

export default AdminUpdatePartner;
